import { IUserData } from "./../../core/models/user.model";
import { Injectable } from "@angular/core";
import { HttpClientService } from "./http.service";
import { IAddressData } from "../models/address.model";

@Injectable({ providedIn: "root" })
export class UsersService {
  userId = null;
  userOrders = [];
  drivers = [];
  constructor(private http: HttpClientService) {}

  getAllUsers(IUserData: IUserData) {
    return this.http.get("users", IUserData);
  }
  getUserData(userId: number) {
    return this.http.get(`users/profile`, { user_id: userId });
  }
  createUser(body) {
    return this.http.postFormData(body, "users/create");
  }
  updateUser(body) {
    return this.http.postFormData(body, `users/update`);
  }

  deleteUser(userId) {
    return this.http.delete({}, `users/${userId}/delete`);
  }
  changePassword(body, userId: number) {
    return this.http.post(body, "users/change-password", { user_id: userId });
  }
  addNotes(body) {
    return this.http.post(body, "users/add-note");
  }
  sendSmsToUser(body) {
    return this.http.post(body, "users/send-message");
  }

  // user adresses
  getAllAddresses(options: IAddressData) {
    return this.http.get("addresses", options);
  }

  createAddress(body) {
    return this.http.postFormData(body, "addresses");
  }

  updateAddress(id, body) {
    return this.http.postFormData(body, `addresses/${id}`);
  }

  deleteAddress(id) {
    return this.http.delete({}, `addresses/${id}`);
  }
}
